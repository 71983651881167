import {graphql} from "gatsby";
import React from "react";

import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import SeoMetaStuff from "../components/SeoMetaStuff";
import StructuredData from "../components/StructuredData";
import SanityRichText from "../components/commons/SanityRichText";

const Security = ({data}) => {
  const cmsRef = data.allSanityPols.nodes[0];
  const seoData = data.allSanitySeoMeta.nodes[0];

  return (
    <div className='siteWrap tou'>
      <Header />
      {seoData && <SeoMetaStuff data={seoData} />}
      <StructuredData />
      <SanityRichText className='blocks' blocks={cmsRef._rawPContent} />
      <Footer />
    </div>
  );
};
export const query = graphql`
  {
    allSanityPols(filter: {pTitle: {eq: "Security"}}) {
      nodes {
        pTitle
        _rawPContent
      }
    }
    allSanitySeoMeta(filter: {page: {eq: "security"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
  }
`;

export default Security;
